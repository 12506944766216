import React, { useEffect } from 'react'

import styles from './Home.module.css'

import headshot from '../../../assets/Home/Headshot.png'
import graduationCapIcon from '../../../assets/Home/Icons/graduate-cap.png'
import laptopIcon from '../../../assets/Home/Icons/laptop.png'
import gearsIcon from '../../../assets/Home/Icons/gears.png'

function scrollToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

export default function Home({ setSelectedLink }) {

  useEffect(() => {
    setSelectedLink('Home')
  },[setSelectedLink])

  return (
    <div className={styles.homePage}>
      <section className={styles.section}>
        <div className={styles.profile}>
          <div className={styles.photoContainer}>
            <img className={styles.photo} src={headshot} alt="Headshot"></img>
          </div>
          <div>
            <h2 className={styles.jobTitle}>Graduate Software Engineer at <a href="https://www.opentext.com/">OpenText</a>, Newbury</h2>
            <h2 className={styles.jobDate}>July 2022 - present</h2>
          </div>

        </div>
      </section>
      <section className={styles.section}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <h1>Enthusiastic Learner</h1>
            <img className={styles.icons} src={graduationCapIcon} alt='Graduation Cap'></img>
          </li>
          <li className={styles.listItem}>
            <h1>Self-Taught</h1>
            <img className={styles.icons} src={laptopIcon} alt='Laptop'></img>
          </li>
          <li className={styles.listItem}>
          <h1>Industry experience</h1>
            <img className={styles.icons} src={gearsIcon} alt='Gears'></img>
          </li>
        </ul>
      </section>
      <section className={styles.section}>
        <div className={styles.bio}>
          <h2><u>My Story So Far</u></h2>
          <p className={styles.paragraph}>In 2021 I graduated from the University of Surrey, achieving a First-Class BSc in Physics.</p>
          <p className={styles.paragraph}>My degree led me to TRIUMF lab in Canada where I carried out a year of research in nuclear physics. This introduced me to C++ and I realised that coding was something I would like to build my career around.</p>
          <p className={styles.paragraph}>After graduating I gained more exposure to the field by teaching myself front-end web development with React.js. </p>
          <p className={styles.paragraph}>In 2022 I accepted a Graduate Software Engineer role at Micro Focus (now OpenText) joining the Visual COBOL compiler team where I remain to this day.</p>
        </div>
      </section>
      <button onClick={scrollToTop} className={styles.backToTop}>Back to the top</button>
    </div>
  )
}
