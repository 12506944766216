import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';

console.log("24/09/23 20:11")

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
