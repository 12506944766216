import React from 'react';
import { Link, Outlet } from 'react-router-dom';

import styles from './Navbar.module.css';



export default function Navbar({ selectedLink }) {
  return (
    <>
      <div className={styles.navbar}>
        <h1>
          <Link className={styles.title} to='/'>Joe McAfee</Link>
        </h1>
        <ul className={styles.links}>
          <li className={styles.links}>
            <Link className={styles.link} style={{ color : selectedLink === 'Home' ? 'var(--primary-color)' : 'black' }} to='/'>Home</Link>
          </li>
          <li className={styles.links}>
            <Link className={styles.link} style={{ color : selectedLink === 'Contact' ? 'var(--primary-color)' : 'black' }} to='/contact'>Contact</Link>
          </li>
        </ul>
      </div>
      <Outlet />
    </>
  )
}
