import React, { useEffect } from 'react';

import styles from './Contact.module.css';

import LinkedInIcon from '../../../assets/Contact/Icons/LinkedIn.png';
import githubIcon from '../../../assets/Contact/Icons/Github.png';
import EmailIcon from '../../../assets/Contact/Icons/Email.png';

const emailName = 'Joseph.McAfee';
const emailDomain = '@Outlook.com';

export default function Contact({ setSelectedLink }) {

  useEffect(() => {
    setSelectedLink('Contact');
  },[setSelectedLink])

  return (
    <div className={styles.contactPage}>
      <section className={styles.contact}>
        <div className={styles.iconContainer}>
          <img className={styles.emailIcon} src={EmailIcon} alt='Email Icon'></img>
        </div>
        <h1 className={styles.header}>Email Me</h1>
        <p className={styles.info}>{emailName}<wbr></wbr>{emailDomain}</p>
        <a className={styles.link} href={`mailto:${emailName}${emailDomain}`}>Send Email</a>
      </section>

      <section className={styles.contact}>
        <div className={styles.iconContainer}>
          <img className={styles.githubIcon} src={githubIcon} alt='Github Logo'></img>
        </div>
        <h1 className={styles.header}>Check out some code</h1>
        <p className={styles.info}>MortisMc on Github</p>
        <a className={styles.link} href='https://github.com/MortisMc'>View Profile</a>
      </section>

      <section className={styles.contact}>
        <div className={styles.iconContainer}>
          <img className={styles.linkedInIcon} src={LinkedInIcon} alt='LinkedIn Logo'></img>
        </div>
        <h1 className={styles.header}>Message Me</h1>
        <p className={styles.info}>Joe McAfee on LinkedIn</p>
        <a className={styles.link} href='https://www.linkedin.com/in/joe-mcafee-7a60a7203/'>View Profile</a>
      </section>
    </div>
  )
}
