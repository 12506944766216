import React, { useEffect } from 'react'

import styles from './NoPage.module.css';

export default function NoPage({ setSelectedLink }) {
  useEffect(() => {
    setSelectedLink('');
  },[setSelectedLink])

  return (
    <div className={styles.NoPage}>      
      <h1 className={styles.message}>Page not found</h1>
    </div>
  )
}
