import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Navbar from '../Navbar';
import Home from '../pages/Home'
import Contact from '../pages/Contact';
import NoPage from '../pages/NoPage';

require('./App.css')

function App() {

  const [selectedLink, setSelectedLink] = useState('Home');

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Navbar selectedLink={selectedLink} />} >
          <Route index element={<Home setSelectedLink={setSelectedLink} />} />
          <Route path='contact'  element={<Contact setSelectedLink={setSelectedLink} />} />
          <Route path='*' element={<NoPage setSelectedLink={setSelectedLink} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
